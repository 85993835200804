<template>
  <div class="content-wrapper ">
    <h1>Time Line</h1>

    <b-card class="mb-4">
      <b-button @click="$router.push('/axerta/lista-dossier/')" size="sm">
        Vai ai dossier
      </b-button>
    </b-card>

    <b-card class="mb-4">
      <h4>Legenda</h4>
      <b-row v-if="loading">
        Caricamento in corso...
      </b-row>
      <b-row v-else>
        <div
          v-for="manager in uniqueManagers"
          :key="manager.id"
          class="col-12 col-md-4 col-lg-2 flex align-items-center"
        >
          <b-icon-circle-fill
            :style="{ color: manager.color }"
          ></b-icon-circle-fill>
          <span class="ml-2 font-xs">{{ manager.name }}</span>
        </div>
      </b-row>
    </b-card>

    <b-card v-if="!currentPlace">
      <GmapAutocomplete
        class="w-100"
        @place_changed="setPlace"
        placeholder="Inserisci il luogo di partenza"
      />
    </b-card>
    <b-card v-else>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-icon icon="geo-alt-fill"></b-icon>
          <span class="ml-2">{{ currentPlace.formatted_address }}</span>
        </div>
        <b-button
          @click="currentPlace = null"
          variant="outline-danger"
          size="sm"
          pill
        >
          <b-icon icon="x"></b-icon>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <div v-if="loadingFullCalendar" class="w-100">
        Caricamento in corso...
      </div>
      <div v-else id="chart">
        <FullCalendar
          ref="fullCalendar"
          class="demo-app-calendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <div
              :id="'tooltip-target-' + arg.event.id"
              class="flex flex-column cursor-pointer"
            >
              <b>{{ arg.timeText }}</b>
              <i>{{ trimTo20Characters(arg.event.title) }}</i>
              <b-tooltip
                :target="'tooltip-target-' + arg.event.id"
                triggers="hover"
              >
                <b>{{ arg.event.title }}</b>
              </b-tooltip>
            </div>
          </template>
        </FullCalendar>
      </div>
    </b-card>

    <!-- <b-sidebar
      class="right-sidebar"
      id="sidebar-ordine-di-servizio"
      right
      shadow
      lazy
      :visible="true"
      style="z-index:901 !important"
      v-model="sidebarOrdineVisible"
      @hidden="resetData"
    > -->

    <b-sidebar
      class="right-sidebar"
      id="sidebar-ordine-di-servizio"
      right
      shadow
      lazy
      :visible="true"
      style="z-index:901 !important"
      v-model="sidebarOrdineVisible"
    >
      <sbOrdineDiServizio
        :idOrdine="tempIdOrdine"
        :dataInizio="tempDataInizio"
        :dataFine="tempDataFine"
        :cieSelezionato="tempIdCieSelezionato"
        :dossierName="tempDossier.text"
        :dossierID="tempDossier.value"
        :currentPlaceCoords="sidebarData.currentPlaceCoords"
        :currentPlace="sidebarData.currentPlace"
        @bv_toggle_collapse="resetData"
      ></sbOrdineDiServizio>
    </b-sidebar>

    <b-modal
      id="modal-seleziona-dossier"
      v-model="modalDossierVisible"
      title="Seleziona Dossier"
    >
      Su quale dossier stai lavorando?

      <b-form-select
        v-model="tempDossier"
        @change="handleChangeDossier($event)"
      >
        <option
          v-for="dossier in dossierOptions"
          :key="dossier.value"
          :value="dossier"
          >{{ dossier.text }}</option
        >
      </b-form-select>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="openSidebarEvent"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import multiMonthPlugin from "@fullcalendar/multimonth";

import itLocale from "@fullcalendar/core/locales/it";

import sbOrdineDiServizio from "@/components/axerta/sbOrdineDiServizio.vue";

import { iconExists, getIcon, buildIcon } from "@iconify/vue2";

import { tail, truncate } from "lodash";
import { calcDistance } from "../../utils/geometry";

export default {
  created() {
    this.getDossierManagerOptions();
    this.getCIEOptions();
    this.getDossierOptions();
    this.getOrdini();
    this.getCieSet_Mezzi();
    this.getCieSet_Tools();
  },

  components: {
    FullCalendar,
    sbOrdineDiServizio,
  },
  data() {
    return {
      dossierSelected: {},
      modalDossierVisible: false,
      tempIdCieSelezionato: 0,
      tempIdOrdine: 0,
      tempIdDossier: 0,
      tempDossier: "",
      tempDataInizio: "",
      tempDataFine: "",
      tempEvent: null,
      sidebarOrdineVisible: false,
      calendarOptions: {
        resourceOrder: "distance, title",
        stickyHeaderDates: true,
        height: "555px",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin,
          multiMonthPlugin,
        ],
        locale: itLocale,
        dateClick: this.onDateClick,
        select: this.onSelect,
        unselect: this.onUnselect,
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "resourceTimelineMonth,multiMonthYear",
        },
        initialView: "resourceTimelineMonth",

        resources: [],

        events: [],
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,

        eventClick: this.onEventClick,
        eventsSet: this.onEvents,
        resourceLabelDidMount: this.onResourceLabelDidMount,
        eventMouseEnter: this.onEventMouseEnter,

        managers: [],

        slotMinWidth: 125,
        resourceAreaWidth: 200,
      },

      currentEvents: [],

      filter: {
        dossierManager: null,
        CIE: null,
        dossier: null,
        customer: null,
        costoKm: ["", ""],
        mezzi: [],
        tools: [],
        disponibilita: [],
      },
      dossierManagerOptions: [{ value: null, text: "Dossier Manager" }],
      CIEOptions: [{ value: null, text: "CIE" }],
      dossierOptions: [{ value: null, text: "Dossier" }],
      customerOptions: [{ value: null, text: "Customer" }],
      loading: false,
      loadingFullCalendar: false,
      mezziOptions: [],
      toolsOptions: [],
      distanzeFasulle: [
        "5 km",
        "12 km",
        "13 km",
        "21 km",
        "21 km",
        "38 km",
        "59 km",
        "62 km",
        "130 km",
      ],
      currentPlace: null,
      currentPlaceCoords: null,
      sidebarData: {},
    };
  },

  methods: {
    handleChangeDossier(e) {
      this.dossierSelected = e;
    },
    setPlace(place) {
      this.currentPlace = place;
      this.currentPlaceCoords = place.geometry.location.toJSON();

      this.getOrdini();
    },
    calcDistance(point1, point2) {
      const R = 6371; // Radius of the Earth in km
      const lat1 = this.deg2rad(point1.lat);
      const lat2 = this.deg2rad(point2.lat);
      const lon1 = this.deg2rad(point1.lng);
      const lon2 = this.deg2rad(point2.lng);

      const dLat = lat2 - lat1;
      const dLon = lon2 - lon1;

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c; // Distance in km

      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    onResourceLabelDidMount() {
      const resourceCells = tail(
        document.querySelectorAll("span.fc-datagrid-cell-main")
      );

      for (let i = 0; i < resourceCells.length; i++) {
        let resourceCell = resourceCells[i];
        let resourceMezzi = this.calendarOptions.resources[i].skillsRisorsa
          .mezzi;
        let resourceTools = this.calendarOptions.resources[i].skillsRisorsa
          .tools;

        const mezziIconsAppended = resourceCell.querySelector(
          ".icon-container.mezzi"
        );
        const toolsIconsAppended = resourceCell.querySelector(
          ".icon-container.tools"
        );

        if (!mezziIconsAppended) {
          this.appendIcons(resourceCell, resourceMezzi, [
            "icon-container",
            "mezzi",
          ]);
        }

        if (!toolsIconsAppended) {
          this.appendIcons(resourceCell, resourceTools, [
            "icon-container",
            "tools",
          ]);
        }
      }
    },

    appendIcons(resourceCell, items, classes) {
      const iconContainer = document.createElement("div");
      iconContainer.classList.add(...classes);

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item.enabled && iconExists(item.icon)) {
          const svg = this.createSVG(item.icon);
          iconContainer.appendChild(svg);
        } else {
          continue;
        }
      }

      resourceCell.appendChild(iconContainer);
    },

    createSVG(icon) {
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", "24px");
      svg.setAttribute("height", "24px");
      svg.innerHTML = buildIcon(getIcon(icon), {
        color: "#000000",
        width: "24px",
        height: "24px",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "aria-hidden": "true",
        role: "img",
        viewBox: "0 0 24 24",
        class: "iconify iconify--mdi",
      }).body;
      return svg;
    },
    getCieSet_Mezzi() {
      const service = new AxiosService("Option/CieSet_Mezzi");
      service.read().then((res) => {
        this.mezziOptions = res;
      });
    },
    getCieSet_Tools() {
      const service = new AxiosService("Option/CieSet_Tools");
      service.read().then((res) => {
        this.toolsOptions = res;
      });
    },
    openSidebarEvent() {
      this.modalDossierVisible = false;
      this.sidebarOrdineVisible = true;
      this.tempIdDossier = null;
      this.tempDataInizio = this.tempEvent.startStr
        ? this.tempEvent.startStr
        : this.tempEvent.dateStr;
      this.tempDataFine = this.tempEvent.endStr
        ? this.tempEvent.endStr
        : this.tempEvent.dateStr;
      this.tempIdCieSelezionato = Number(this.tempEvent.resource.id);
    },
    onDateClick(event) {
      console.log("onDateClick EVENT ", event);

      if (this.currentPlace.formatted_address) {
        console.log("onDateClick CURRENTPLACE", this.currentPlace);
      }

      this.tempIdOrdine = 0;
      this.dossierName = "";
      this.tempDossier = "";

      this.modalDossierVisible = true;
      this.tempEvent = event;

      this.sidebarData.currentPlace = this.currentPlace;
      this.sidebarData.currentPlaceCoords = this.currentPlaceCoords;
    },
    onSelect(event) {
      this.modalDossierVisible = true;
      this.tempEvent = event;
    },
    onUnselect() {
      this.tempIdDossier = null;
      this.tempDataInizio = "";
      this.tempDataFine = "";
    },
    onEventClick(event) {
      if (!event || event.id == 0) return;

      this.tempIdOrdine = Number(event.event.id);

      this.sidebarData.currentPlace = null;
      this.sidebarData.currentPlaceCoords = null;

      if (this.tempIdOrdine) {
        this.sidebarOrdineVisible = true;
      }
    },
    resetData() {
      console.log("CHIUDO LA SIDEBAR", this.currentItem, this.tmpItem);
      this.currentItem = null;
      this.tmpItem = {
        text: "",
        value: 0,
      };

      this.tempIdOrdine = 0;
      this.dossierName = "";
      this.sidebarOrdineVisible = false;
      this.tempDataInizio = "";
      this.tempDataFine = "";
      this.tempIdCieSelezionato = 0;
      this.tempDossier = "";
      this.calendarOptions.resources.splice(0);
      this.calendarOptions.managers.splice(0);
      this.calendarOptions.events.splice(0);
      this.calendarOptions = {
        resourceOrder: "distance, title",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin,
          multiMonthPlugin,
        ],
        locale: itLocale,
        dateClick: this.onDateClick,
        select: this.onSelect,
        unselect: this.onUnselect,
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "resourceTimelineMonth,multiMonthYear",
        },
        initialView: "resourceTimelineMonth",

        resources: [],

        events: [],
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,

        eventClick: this.onEventClick,
        eventsSet: this.onEvents,
        resourceLabelDidMount: this.onResourceLabelDidMount,
        eventMouseEnter: this.onEventMouseEnter,

        managers: [],

        slotMinWidth: 125,
        resourceAreaWidth: 200,
      };
      this.getOrdini();
    },
    async getOrdini() {
      this.loading = true;
      this.loadingFullCalendar = true;

      this.calendarOptions.events.splice(0);
      this.calendarOptions.resources.splice(0);
      this.calendarOptions.managers.splice(0);

      const service = new AxiosService(
        "DossierManager/GetOrdiniDiServizioCalendar"
      );

      service
        .read()
        .then(async (res) => {
          // this.calendarOptions.resources = res.resources;
          // this.calendarOptions.events = res.events;
          // this.calendarOptions.managers = res.managers;
          let { resources, events, managers } = res;

          for (let i = 0; i < resources.length; i++) {
            let resource = resources[i];
            resource.location = await this.getResourceLocation(resource.id);
            if (this.currentPlace) {
              resource.distance = calcDistance(
                this.currentPlaceCoords,
                resource.location
              );
            } else {
              resource.distance = 0;
            }

            resource.title =
              resource.distance.toFixed(0) + " km - " + resource.title;
          }

          // resources = resources = sortBy(resources, "distance");

          for (let i = 0; i < events.length; i++) {
            let event = events[i];
            if (event.url == null) {
              delete event.url;
            }
          }

          this.calendarOptions.resources.push(...resources);
          this.calendarOptions.events.push(...events);
          this.calendarOptions.managers.push(...managers);
        })
        .finally(() => {
          this.loading = false;
          this.loadingFullCalendar = false;
        });
    },

    async getResourceLocation(resourceId) {
      const service = new AxiosService(
        "DossierManager/GetCieSkills/" + resourceId
      );
      let lat;
      let lng;
      await service.read().then((res) => {
        lat = res.latitudine == null ? 0 : Number(res.latitudine);
        lng = res.longitudine == null ? 0 : Number(res.longitudine);
      });
      return { lat, lng };
    },
    getDossierManagerOptions() {
      const service = new AxiosService("UserBo");
      service.read().then((res) => {
        for (let i = 0; i < res.length; i++) {
          let element = res[i];
          this.dossierManagerOptions.push({
            value: element.id,
            text: element.nome + " " + element.cognome,
          });
        }
      });
    },
    getCIEOptions() {
      const service = new AxiosService("User");
      service.read().then((res) => {
        for (let i = 0; i < res.length; i++) {
          let element = res[i];
          this.CIEOptions.push({
            value: element.id,
            text: element.nome + " " + element.cognome,
          });
        }
      });
    },
    getDossierOptions() {
      const service = new AxiosService("DossierManager/GetDossiers");
      service.read().then((res) => {
        for (let i = 0; i < res.length; i++) {
          let element = res[i];
          this.dossierOptions.push({
            value: element.id,
            text: element.codice + " - " + element.titolo,
          });
        }
      });
    },
    trimTo20Characters(str) {
      if (str.length > 20) {
        return truncate(str, { length: 20 }) + "...";
      }
      return str;
    },
  },
  computed: {
    uniqueManagers() {
      // Use a Set to store unique managers by their ids
      const uniqueManagerIds = new Set();
      // Filter out duplicate managers based on their ids
      return this.calendarOptions.managers.filter((manager) => {
        if (!uniqueManagerIds.has(manager.id)) {
          uniqueManagerIds.add(manager.id);
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
  z-index: 999;
}
</style>
